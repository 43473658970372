import React from 'react'
import { Row, Column12 } from './Grid'

export const GridGuide = () => {
  return (
    <div className="guide">
      <Row
        className="row"
        style={{
          position: 'fixed',
          top: 0,
          left: 'var(--ui-grid-padding)',
          height: '100%',
          width: 'calc(100% - 2 * var(--ui-grid-padding))',
        }}
      >
        {[...Array(12).keys()].map((el, i) => (
          <Column12 key={i} initial={1} medium={1} />
        ))}
      </Row>
    </div>
  )
}
