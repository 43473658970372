import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import useUIContext from 'context/ui'

import Card from './Card'
import ViewportEnter from 'components/motion/ViewportEnter'
import Portal from 'components/ui/Portal'

import s from './ContactCards.module.scss'
const cn = classNames.bind(s)

const _ = {
  maxLength: 10,
}

const ContactCards = ({ data, ...props }) => {
  const [showCards, setShowCards] = useState(false)
  const [maxIndex, setMaxIndex] = useState(_.maxLength)
  const isPageTransitioning = useUIContext((s) => s.isPageTransitioning)
  const constraintRef = useRef()

  return (
    <ViewportEnter onEnter={() => setShowCards(true)} onExit={() => setShowCards(false)} threshold={0} once={false}>
      <div className={cn('wrapper', { inView: showCards })}>
        <Portal>
          <ul className={cn('cards', { showCards, hide: isPageTransitioning })}>
            {data.slice(0, _.maxLength).map((item, i) => (
              <Card
                key={i}
                card={item.card}
                subject={item.subject || ''}
                email={item.email_address || {}}
                index={i}
                total={data.slice(0, _.maxLength).length}
                maxIndex={maxIndex}
                constraintRef={constraintRef}
                show={showCards}
                onCardDragged={setMaxIndex}
              />
            ))}
          </ul>
          <div className={cn('constraint')} ref={constraintRef} />
        </Portal>
      </div>
    </ViewportEnter>
  )
}

ContactCards.propTypes = {
  data: PropTypes.array,
}

export default ContactCards
