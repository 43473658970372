import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import s from './AspectRatio.module.scss'

const cn = classNames.bind(s)

const getCSSVariables = ({ ratio, mediumRatio, desktopRatio }) => ({
  '--ratio': 1 / ratio,
  '--medium-ratio': 1 / (mediumRatio || ratio),
  '--desktop-ratio': 1 / (desktopRatio || mediumRatio || ratio),
})

const AspectRatio = ({ children, ratio = 1, mediumRatio, desktopRatio, className, ...props }) => (
  <div className={cn('wrapper')} style={getCSSVariables({ ratio, mediumRatio, desktopRatio })} {...props}>
    <div className={cn('content', className)}>{children}</div>
  </div>
)

AspectRatio.propTypes = {
  ratio: PropTypes.number.isRequired,
  mediumRatio: PropTypes.number,
  desktopRatio: PropTypes.number,
}

AspectRatio.defaultProps = {
  ratio: 1,
}

export default AspectRatio
