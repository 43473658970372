import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from '@reach/router'
import { useWindowWidth, useWindowHeight } from '@react-hook/window-size'
import classNames from 'classnames/bind'

import useUIContext from 'context/ui'

import s from './ThemeWaypoint.module.scss'

const cn = classNames.bind(s)

const generateId = () => Math.random().toString(36).substring(2, 12)

const getLast = (a) => a[a.length - 1]

const getThemeFromScrollWaypoints = (value, waypoints = []) => {
  const passedWaypoints = waypoints.filter(({ offset }) => value > offset)
  return getLast(passedWaypoints)?.after || null
}

export const SideEffects = () => {
  const { key } = useLocation()
  const lastKey = useRef(key)
  const viewportWidth = useWindowWidth()
  const viewportHeight = useWindowHeight()
  const removeWaypointKey = useUIContext((s) => s.removeWaypointKey)
  const waypoints = useUIContext((s) => s.waypoints)
  const isHorizontal = useUIContext((s) => s.isHorizontalScrollActive)
  const scrollY = useUIContext((s) => s.scrollY)
  const scrollX = useUIContext((s) => s.scrollX)
  const setCurrentTheme = useUIContext((s) => s.setCurrentTheme)
  const setShouldAnimatePageBg = useUIContext((s) => s.setShouldAnimatePageBg)

  useEffect(() => {
    return () => {
      removeWaypointKey(lastKey.current)
      lastKey.current = key
    }
  }, [key, removeWaypointKey])

  useEffect(() => {
    return () => {
      setShouldAnimatePageBg(false)
      setCurrentTheme(null)
    }
  }, [setCurrentTheme, setShouldAnimatePageBg])

  useEffect(() => {
    const routeWaypointsValues = Object.values(waypoints?.[key] || {})
    if (!routeWaypointsValues.length) return
    const sorted = routeWaypointsValues.sort((a, b) => a.offset - b.offset)
    const viewportSize = isHorizontal ? viewportWidth : viewportHeight
    // console.log('ThemeWaypoint [subscribed]:', { key, isHorizontal, viewportWidth, viewportHeight, waypoints })
    const scroll = isHorizontal ? scrollX : scrollY
    let isAnimating = false

    const interval = setInterval(() => {
      isAnimating = false
    }, 150)

    const unsubscribe = scroll.onChange((v) => {
      if (isAnimating === true) return
      setCurrentTheme(getThemeFromScrollWaypoints(Math.ceil(v) + viewportSize, sorted))
      isAnimating = true
    })

    return () => {
      clearInterval(interval)
      if (typeof unsubscribe !== 'function') return
      unsubscribe()
      // console.log('ThemeWaypoint [unsubscribed]:', { key, isHorizontal, viewportWidth, viewportHeight, waypoints })
    }
  }, [key, isHorizontal, viewportWidth, viewportHeight, waypoints, setCurrentTheme])

  return null
}

const ThemeWaypoint = ({ after, className }) => {
  const ref = useRef()
  const { current: id } = useRef(generateId())
  const viewportWidth = useWindowWidth()
  const viewportHeight = useWindowHeight()
  const { key } = useLocation()
  const isHorizontal = useUIContext((s) => s.isHorizontalScrollActive)
  const registerWaypoint = useUIContext((s) => s.registerWaypoint)
  const removeWaypointKeyId = useUIContext((s) => s.removeWaypointKeyId)

  useEffect(() => {
    if (!ref.current) return
    const orientation = isHorizontal ? 'horizontal' : 'vertical'
    const offset = ref.current[isHorizontal ? 'offsetLeft' : 'offsetTop']
    registerWaypoint(key, id, { after, orientation, offset, viewportWidth, viewportHeight })
    return () => {
      removeWaypointKeyId(key, id)
    }
  }, [after, ref, key, registerWaypoint, isHorizontal, viewportWidth, viewportHeight, id])

  return <div ref={ref} className={cn('wrapper', className)} />
}

ThemeWaypoint.propTypes = {
  after: PropTypes.oneOf(['light', 'dark']).isRequired,
}

export default ThemeWaypoint
