import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import s from './Grid.scss'

const cn = classNames.bind(s)

export const Row = ({ ...props }) => {
  return <div className={cn('row')} {...props} />
}

export const Column12 = ({
  initial = 12,
  medium,
  desktop,
  large,
  className,
  center = false,
  right = false,
  ...props
}) => {
  return (
    <div
      className={cn(
        `col--${initial}`,
        !isNaN(medium) && `col--medium--${medium}`,
        !isNaN(desktop) && `col--desktop--${desktop}`,
        !isNaN(large) && `col--large--${large}`,
        center && `align--center`,
        right && `align--right`,
        className,
      )}
      {...props}
    />
  )
}

Column12.propTypes = {
  initial: PropTypes.number,
  medium: PropTypes.number,
  desktop: PropTypes.number,
  large: PropTypes.number,
  className: PropTypes.string,
  center: PropTypes.bool,
  right: PropTypes.bool,
}
