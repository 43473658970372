import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import classNames from 'classnames/bind'
import { withPreview } from 'gatsby-source-prismic-graphql'

import useUIContext from 'context/ui'

import ThemeWaypoint from 'components/ui/ThemeWaypoint'
import Container from 'components/layout/Container'
import Button from 'components/ui/Button'
import Image from 'components/ui/Image'
import Clickable from 'components/ui/Clickable'
import { Row, Column12 } from 'components/ui/Grid'
import { Headline1, BodyCopy } from 'components/ui/Typography'
import SplitText from 'components/motion/SplitText'
import ContactCards from 'components/ui/ContactCards'
import { PrismicLink } from 'prismic'

import s from './Footer.module.scss'
const cn = classNames.bind(s)

const query = graphql`
  query Footer {
    prismic {
      site_settings(uid: "site-settings", lang: "en-us") {
        footer_title
        footer_link {
          ...PrismicLinkFrag
        }
        footer_link_text
        footer_links_left {
          link {
            ...PrismicLinkFrag
          }
          link_text
        }
        footer_links_right {
          link {
            ...PrismicLinkFrag
          }
          link_text
        }
        footer_logo_right
        footer_logo_link_right {
          ...PrismicLinkFrag
        }
        contact_cards {
          card
          subject
          email_address {
            ...PrismicLinkFrag
          }
        }
        additional_footer_link {
          ...PrismicLinkFrag
        }
        additional_footer_link_text
      }
    }
  }
`

const FooterContent = ({ prismic, ...props }) => {
  const data = { ...prismic?.site_settings } || {}
  const isPageTransitioning = useUIContext((s) => s.isPageTransitioning)

  return (
    <>
      <Container className={cn('container', { isPageTransitioning })}>
        <Row>
          <Column12 initial={10} medium={12} center>
            <div className={cn('top')}>
              <Row>
                <Column12 desktop={8} large={6} center>
                  {data.footer_title && (
                    <Headline1 className={cn('title')}>
                      <SplitText>{data.footer_title[0].text}</SplitText>
                    </Headline1>
                  )}
                  {data.footer_link && (
                    <Button className={cn('btn')} to={data.footer_link.url} isBold>
                      {data.footer_link_text}
                    </Button>
                  )}
                </Column12>
              </Row>
            </div>
            <div className={cn('btm')}>
              <Row>
                <Column12 initial={12} medium={4} className={cn('leftWrapper')}>
                  {data?.footer_links_left?.map(({ link, link_text }, i) => (
                    <BodyCopy className={cn('linkLeft')} key={i}>
                      <Button to={PrismicLink.url(link)}>{link_text}</Button>
                    </BodyCopy>
                  ))}
                  <div className={cn('social')}>
                    {data?.footer_links_right?.map(({ link, link_text }, i, { length }) => (
                      <BodyCopy key={i}>
                        <Button to={PrismicLink.url(link)}>{link_text}</Button>
                        {i + 1 < length && <span role="presentation">,&nbsp;</span>}
                      </BodyCopy>
                    ))}
                  </div>
                </Column12>
                <Column12 initial={12} medium={4} desktop={3} right>
                  <div className={cn('rightWrapper')}>
                    <div className={cn('rightInner')}>
                      {data?.additional_footer_link && (
                        <div className={cn('additionalLink')}>
                          <BodyCopy>
                            <Button to={PrismicLink.url(data.additional_footer_link)} entryDelay={1.8}>
                              {data.additional_footer_link_text}
                            </Button>
                          </BodyCopy>
                        </div>
                      )}

                      {data?.footer_logo_right && (
                        <Clickable to={PrismicLink.url(data.footer_logo_link_right)} className={cn('rightLogo')}>
                          <Image src={data.footer_logo_right.url} alt={data.footer_logo_right.alt} />
                        </Clickable>
                      )}
                    </div>
                  </div>
                </Column12>
              </Row>
            </div>
          </Column12>
        </Row>
      </Container>
      <ContactCards data={data?.contact_cards} />
    </>
  )
}

const FooterContentWithData = memo((props) => (
  <StaticQuery
    query={`${query}`} // workaround proptype error: https://github.com/birkir/gatsby-source-prismic-graphql/issues/70#issuecomment-572779682
    render={withPreview((data) => {
      return <FooterContent {...props} {...data} />
    }, query)}
  />
))

FooterContentWithData.displayName = 'FooterContentWithData'

// Wrap static query in element that is persistent
// - VirtualScrollbars gets confused when FooterContent unmounts/re-mounts to show preview data
const Footer = ({ className, simplifiedFooter = false, hasFooterThemeWaypoint = true, ...props }) => (
  <>
    <footer className={cn('footer', className, { simplified: simplifiedFooter })}>
      {hasFooterThemeWaypoint && <ThemeWaypoint after="dark" className={cn('waypoint')} />}
      <div className={cn('inner')}>
        <FooterContentWithData {...props} />
      </div>
    </footer>
  </>
)

Footer.propTypes = {
  simplifiedFooter: PropTypes.bool,
  hasFooterThemeWaypoint: PropTypes.bool,
}

export default Footer
