import { useEffect } from 'react'

export const useOnWebFontLoaded = (cb) => {
  // Detect WebFont load
  useEffect(() => {
    let fallbackTimer
    if ('fonts' in document) {
      document.fonts.onloadingdone = cb
    } else {
      fallbackTimer = setTimeout(cb, 1000)
    }
    return () => {
      document.fonts.onloadingdone = null
      clearTimeout(fallbackTimer)
    }
  }, [])
}

export default useOnWebFontLoaded
