import React from 'react'
import PropTypes from 'prop-types'
import { TransitionState } from 'gatsby-plugin-transition-link'
import classNames from 'classnames/bind'

import useUIContext from 'context/ui'

import DefaultTransitionLink from 'components/motion/DefaultTransitionLink'
import Portal from 'components/ui/Portal'

import s from './DefaultPageTransition.module.scss'
const cn = classNames.bind(s)

const range = (n) => [...Array(n).keys()]

const TransitionElements = ({ transitionStatus, isDefaultType, isHorizontal, isTransitioning }) => {
  const isHorizontalScrollActive = useUIContext((s) => s.isHorizontalScrollActive)

  return (
    <Portal>
      <div
        className={cn('wrapper', {
          [transitionStatus]: isDefaultType,
          isHorizontal: isHorizontal && isHorizontalScrollActive,
          isTransitioning,
        })}
      >
        <div className={cn('bg')} />
        <div className={cn('lines')}>
          {range(10).map((line, index) => (
            <div className={cn('line')} key={index} />
          ))}
        </div>
      </div>
    </Portal>
  )
}

TransitionElements.displayName = 'TransitionElements'

TransitionElements.propTypes = {
  transitionStatus: PropTypes.string,
  isDefaultType: PropTypes.bool,
  isHorizontal: PropTypes.bool,
  isTransitioning: PropTypes.bool,
}

const DefaultPageTransition = (props) => (
  <TransitionState>
    {({ transitionStatus, current } = {}) => {
      const isDefaultType = current?.state?.type === DefaultTransitionLink.type
      const isHorizontal = !!current?.state?.horizontal
      const isTransitioning = transitionStatus !== 'entered'

      return (
        <TransitionElements
          transitionStatus={transitionStatus}
          isDefaultType={isDefaultType}
          isHorizontal={isHorizontal}
          isTransitioning={isTransitioning}
        />
      )
    }}
  </TransitionState>
)

export default DefaultPageTransition
