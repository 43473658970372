import React, { useEffect, useState, useCallback, memo } from 'react'
import PropTypes from 'prop-types'
import { useWindowSize } from '@react-hook/window-size'
import classNames from 'classnames/bind'
import { VirtualScrollbar } from '@14islands/r3f-scroll-rig/scrollbar'
import { useViewportScroll } from 'framer-motion'
import { TransitionState } from 'gatsby-plugin-transition-link'

import { desktop } from 'styles/config/_breakpoints.scss'

import useUIContext from 'context/ui'
import useOnWebFontLoaded from 'lib/useOnWebFontLoaded'

import { GridGuide } from 'components/ui/Grid'
import { SideEffects as ThemeWaypointSideEffects } from 'components/ui/ThemeWaypoint'
import Footer from 'components/ui/Footer'
import DefaultPageTransition from 'components/motion/DefaultPageTransition'

import s from './Layout.module.css'
const cn = classNames.bind(s)

const transitions = ['exiting', 'exited', 'entering', 'entered']
let buffer = transitions.length - 1
let c = 'entered'

// const resetC = (e) => console.log(e)

// eslint-disable-next-line react/display-name
const SideEffects = memo(({ lightTheme, hasVirtualScrollbar, setHasVirtualScrollbar, transition }) => {
  const [viewportWidth] = useWindowSize({ initialWidth: 0 })
  const toggleHiddenHeader = useUIContext((s) => s.toggleHiddenHeader)
  const scrollY = useUIContext((s) => s.scrollY)
  const setIsFontLoaded = useUIContext((s) => s.setIsFontLoaded)
  const setPageTheme = useUIContext((s) => s.setPageTheme)
  const setIsPageTransitioning = useUIContext((s) => s.setIsPageTransitioning)
  const setCursorIcon = useUIContext((s) => s.setCursorIcon)
  const setScrollDirection = useUIContext((s) => s.setScrollDirection)
  const { scrollY: framerScrollY, scrollYProgress } = useViewportScroll()
  const scrollProgress = useUIContext((s) => s.scrollProgress)
  const [isPopState, setIsPopState] = useState(false)

  useEffect(() => {
    if (!window) return
    window.addEventListener('popstate', () => setIsPopState(true))
  }, [])

  useEffect(() => {
    if (transitions.indexOf(transition) === 0 && transitions.indexOf(transition) !== buffer) {
      c = transitions[0]
      buffer = transitions.indexOf(transition)
    } else {
      if (transitions.indexOf(transition) === buffer + 1) {
        c = transitions[transitions.indexOf(transition)]
        buffer = transitions.indexOf(transition)
      }
    }

    if ((c === 'exiting' || c === 'exited') && !isPopState) {
      setIsPageTransitioning(true)
      setCursorIcon(null)
    } else {
      setIsPageTransitioning(false)
    }
  }, [transition])

  useEffect(() => {
    if (!isPopState && transition !== 'entered') {
      setIsPopState(false)
      c = 'entered'
    }
  }, [transition, isPopState])

  useOnWebFontLoaded(() => {
    setIsFontLoaded(true)
    // console.info('Layout', 'WebFont loaded.. update virtual scroll?')
  })

  // Page mount
  // Remove .is-first-page-load class when page unmounts
  useEffect(() => {
    toggleHiddenHeader(false)
    return () => {
      document.documentElement.classList.remove('is-first-page-load')
    }
  }, [toggleHiddenHeader])

  // update y axis scroll if no virtual scrollbar
  useEffect(() => {
    if (hasVirtualScrollbar) return
    let buffer = 0

    const listenY = framerScrollY.onChange((y) => {
      scrollProgress.set(scrollYProgress.current)
      setScrollDirection(buffer < y ? -1 : 1)
      scrollY.set(y)
      buffer = y
    })

    return listenY
  }, [hasVirtualScrollbar])

  // initial theme setting
  useEffect(() => {
    setPageTheme(lightTheme ? 'light' : 'dark')
  }, [setPageTheme, lightTheme])

  // set virtual scrollbar depending on the viewport size
  useEffect(() => {
    setHasVirtualScrollbar(viewportWidth >= parseInt(desktop))
  }, [viewportWidth])

  useEffect(() => {
    // Force scrollY to match the native scroll position (framer-motion does always update until first scroll event)
    scrollY.set(window.pageYOffset)
  }, [scrollY])

  return null
})

SideEffects.propTypes = {
  lightTheme: PropTypes.bool,
  hasVirtualScrollbar: PropTypes.bool,
  setHasVirtualScrollbar: PropTypes.func,
  transition: PropTypes.string,
}

const Layout = ({
  children,
  className,
  lightTheme = false,
  simplifiedFooter = false,
  hasFooterThemeWaypoint,
  footerClassName,
  footer = (
    <Footer
      className={footerClassName}
      simplifiedFooter={simplifiedFooter}
      hasFooterThemeWaypoint={hasFooterThemeWaypoint}
    />
  ),
  ...props
}) => {
  const [hasVirtualScrollbar, _setHasVirtualScrollbar] = useState(false)
  const isHorizontalScrollActive = useUIContext((s) => s.isHorizontalScrollActive)
  const setScrollDirection = useUIContext((s) => s.setScrollDirection)
  const scrollY = useUIContext((s) => s.scrollY)

  const setHasVirtualScrollbar = useCallback(_setHasVirtualScrollbar, [])

  // update y axis scroll if virtual scrollbar present
  const onScrollbarUpdate = useCallback(({ current, direction }) => {
    setScrollDirection(direction)
    scrollY.set(current)
  }, [])

  const isVirtualScrollbarDisabled = !hasVirtualScrollbar || isHorizontalScrollActive

  return (
    <TransitionState>
      {({ mount, transitionStatus, current } = {}) => {
        return (
          <>
            <VirtualScrollbar onUpdate={onScrollbarUpdate} disabled={isVirtualScrollbarDisabled}>
              {(bind) => (
                <main className={cn('layout')} {...props} {...bind}>
                  {children}
                  {footer}
                </main>
              )}
            </VirtualScrollbar>
            <DefaultPageTransition />
            <ThemeWaypointSideEffects />
            <SideEffects
              lightTheme={lightTheme}
              hasVirtualScrollbar={hasVirtualScrollbar}
              setHasVirtualScrollbar={setHasVirtualScrollbar}
              transition={transitionStatus}
            />
            {typeof window !== 'undefined' && window?.location?.search === '?=gridguide' && <GridGuide />}
          </>
        )
      }}
    </TransitionState>
  )
}

Layout.propTypes = {
  footer: PropTypes.node,
  lightTheme: PropTypes.bool,
  simplifiedFooter: PropTypes.bool,
  hasFooterThemeWaypoint: PropTypes.bool,
  footerClassName: PropTypes.string,
}

export default Layout
