import React, { forwardRef } from 'react'
import GatsbyImage from 'gatsby-image'
import PropTypes from 'prop-types'
import replaceQuote from 'lib/replaceQuote'

const get = (img) => img?.childImageSharp?.fluid || img?.src || img?.url || img || ''

const Image = forwardRef(({ src, alt, ...props }, ref) => {
  if (typeof get(src) !== 'object')
    return <img crossOrigin="anonymous" ref={ref} src={get(src)} alt={replaceQuote(alt)} {...props} />
  return (
    <GatsbyImage crossOrigin="anonymous" ref={ref} fluid={get(src)} alt={replaceQuote(alt)} loading="auto" {...props} />
  )
})

Image.displayName = 'Image'

Image.propTypes = {
  src: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  alt: PropTypes.string,
}

export default Image
