import React, { useState, useEffect, forwardRef, useRef, useMemo } from 'react'
import { useWindowSize } from '@react-hook/window-size'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import useUIContext from 'context/ui'

import ViewportEnter from 'components/motion/ViewportEnter'
import s from './SplitText.module.scss'
const cn = classNames.bind(s)

const range = (n) => [...Array(n).keys()]

const _ = {
  delayOffset: 0.1,
}

const calcDelays = (els) => {
  let offsetBuffer = null
  let durationBuffer = 0

  els.forEach((el, i) => {
    const offset = el.current.offsetTop

    if (offset !== offsetBuffer) {
      offsetBuffer = offset
      durationBuffer += _.delayOffset
    }

    el.current.style.transitionDelay = `${durationBuffer + _.delayOffset}s`
  })
}

const SplitText = forwardRef(({ children }, ref) => {
  if (!children) return
  const [viewportWidth] = useWindowSize({ initialWidth: 0, wait: 0 })
  const [isInView, setIsInView] = useState(false)
  const words = useMemo(() => children.split(' '), [children])
  const refEls = range(words.length).map(() => useRef())
  const isFontLoaded = useUIContext((s) => s.isFontLoaded)

  useEffect(() => {
    if (!refEls) return
    calcDelays(refEls)
  }, [viewportWidth, refEls, isFontLoaded])

  return (
    <ViewportEnter onEnter={() => setIsInView(true)}>
      <div className={cn('wrapper', { inView: isInView })} ref={ref}>
        {words.map((word, i) => (
          <span className={cn('line')} key={word + i}>
            <span className={cn('inner')} ref={refEls[i]}>
              {word}
              {i !== words.length - 1 ? '\u00A0' : ''}
            </span>
          </span>
        ))}
      </div>
    </ViewportEnter>
  )
})

SplitText.displayName = 'SplitText'

SplitText.propTypes = {
  children: PropTypes.string,
  onWrap: PropTypes.func,
}

export default SplitText
