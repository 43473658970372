import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { motion } from 'framer-motion'

import AspectRatio from 'components/ui/AspectRatio'
import Image from 'components/ui/Image'
import Clickable from 'components/ui/Clickable'
import Arrow from 'assets/svg/cards-cta.inline.svg'

import s from './ContactCards.module.scss'
const cn = classNames.bind(s)

const variants = {
  peak: {
    y: '-30%',
  },
  show: {
    opacity: 0.2,
  },
  hide: {
    x: 0,
    y: 0,
    transition: {
      duration: 0.45,
    },
  },
}

const Card = ({ card, email, subject, index, total, maxIndex, constraintRef, onCardDragged, show }) => {
  const [zIndex, setZIndex] = useState(total - index)
  const [isDragging, setIsDragging] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const ratio = card.dimensions.width / card.dimensions.height || 1
  const href = email.url.includes('mailto') ? `${email.url}?subject=${subject}` : email

  const onStart = () => {
    setIsActive(true)
    setIsDragging(true)
    setZIndex(maxIndex)
    onCardDragged(maxIndex + 1)
  }

  const onEnd = () => {
    setIsDragging(false)
  }

  // const onClick = () => {
  //   !isActive &&
  // }

  const onHide = () => {
    setZIndex(total - index)
    setIsActive(false)
  }

  useEffect(() => {
    !show && onHide()
  }, [show])

  return (
    <motion.li className={cn('card', { isDragging, isActive })} style={{ zIndex }}>
      <div className={cn('mover')}>
        <motion.div
          drag={isActive}
          dragConstraints={constraintRef}
          onPointerDown={onStart}
          onDragStart={onStart}
          onPointerUp={onEnd}
          onDragEnd={onEnd}
          variants={variants}
          animate={!show && 'hide'}
        >
          <div className={cn('sizer')}>
            <div className={cn('inner')}>
              <AspectRatio ratio={ratio}>
                <Image src={card} alt={card.alt || subject || ''} />
              </AspectRatio>
              {href && (
                <div className={cn('cta')}>
                  <Clickable to={href} className={cn('link')}>
                    <span className={cn('label')}>Let&apos;s talk about it</span>
                    <span className={cn('corner')}>
                      <span className={cn('arrows')}>
                        <span className={cn('main')}>
                          <Arrow />
                        </span>
                        <span className={cn('aux')}>
                          <Arrow />
                        </span>
                      </span>
                      <span className={cn('bg')}></span>
                    </span>
                  </Clickable>
                </div>
              )}
            </div>
          </div>
        </motion.div>
      </div>
    </motion.li>
  )
}

Card.propTypes = {
  card: PropTypes.object,
  email: PropTypes.object,
  subject: PropTypes.string,
  index: PropTypes.number,
  constraintRef: PropTypes.object,
  total: PropTypes.number,
  show: PropTypes.bool,
  maxIndex: PropTypes.number,
  onCardDragged: PropTypes.func,
}

export default Card
