import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Clickable from 'components/ui/Clickable'
import ViewportEnter from 'components/motion/ViewportEnter'

import classNames from 'classnames/bind'
import s from './Button.module.scss'

const cn = classNames.bind(s)

const Button = ({
  to,
  className,
  onClick = () => {},
  disabled = false,
  isBold = false,
  shouldTransitionIn = true,
  children,
  ...props
}) => {
  const [shouldAnimate, setShouldAnimate] = useState(false)
  const [shouldReanimate, setShouldReanimate] = useState({ out: false, in: false })
  const [isInView, setIsInView] = useState(false)

  const onAnimationEnd = () => {
    setShouldAnimate(false)
    setTimeout(() => {
      shouldReanimate.in && shouldReanimate.out && setShouldAnimate(true)
      setShouldReanimate({ out: false, in: false })
    })
  }

  const onMouseEnter = () => {
    setShouldAnimate(true)
    setShouldReanimate({ out: shouldReanimate.out, in: true })
  }

  const onMouseLeave = () => {
    shouldAnimate && setShouldReanimate({ out: true, in: false })
  }

  return (
    <ViewportEnter onEnter={() => setIsInView(true)}>
      <span className={cn('wrapper')}>
        <Clickable
          to={to}
          className={cn('button', 'underline', className, {
            bold: isBold,
            disabled,
            hovered: shouldAnimate,
            shouldTransitionIn,
            inView: shouldTransitionIn && isInView,
          })}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          {...props}
        >
          <span className={cn({ headline4: isBold })}>
            <span className={cn('inner')}>{children}</span>
            <span className={cn('aux')}>{children}</span>
            <span className={cn('lines')}>
              <span className={cn('line')} />
              <span className={cn('line')} onAnimationEnd={onAnimationEnd} />
            </span>
          </span>
        </Clickable>
      </span>
    </ViewportEnter>
  )
}

export default Button

Button.propTypes = {
  to: PropTypes.string,
  isBold: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  shouldTransitionIn: PropTypes.bool,
}
